import React from "react";
import { Route, Switch } from "react-router-dom";
import { ThemeProvider } from "react-jss";
import { createUseStyles, useTheme } from "react-jss";

import FoodListPage from "./components/FoodListPage";
import Login from "./components/Login";
import MathPage from "./components/MathPage";
import MissingLetterPage from "./components/MissingLetterPage";
import Navigation from "./components/Navigation";
import RpgGamePage from "./components/RpgGamePage";
import SpanishVideosPage from "./components/SpanishVideosPage";
import SpellingTestPage from "./components/SpellingTestPage";
import StatusPage from "./components/StatusPage";
import TypingGamePage from "./components/TypingGamePage";
import WordSearchPage from "./components/WordSearchPage";

import "./css/App.css";
import useLocalStorage from "./functions/useLocalStorage";

const theme = {
    background: "white",
};

const useStyles = createUseStyles({
    horizontalLine: {
        "margin-bottom": "2em",
    },
});

function App() {
    const theme = useTheme();
    const classes = useStyles({ theme });
    const [username, setUsername] = useLocalStorage("USER", "");
    const updateUser = (username) => {
        setUsername(username);
    };
    return (
        <ThemeProvider theme={theme}>
            <header>
                <Navigation username={username} />
            </header>
            <hr className={classes.horizontalLine} />
            <Switch>
                <Route
                    exact
                    path="/"
                    render={() => (
                        <Login username={username} updateUser={updateUser} />
                    )}
                />
                <Route exact path="/math" component={MathPage} />
                <Route exact path="/word-search" component={WordSearchPage} />
                <Route
                    exact
                    path="/missing-letter"
                    component={MissingLetterPage}
                />
                <Route
                    exact
                    path="/spelling-test"
                    component={SpellingTestPage}
                />
                <Route
                    exact
                    path="/spanish-videos"
                    component={SpanishVideosPage}
                />
                <Route exact path="/rpg-game" component={RpgGamePage} />
                <Route exact path="/food-list" component={FoodListPage} />
                <Route exact path="/status" component={StatusPage} />
                <Route exact path="/typing" component={TypingGamePage} />
            </Switch>
        </ThemeProvider>
    );
}

export default App;
