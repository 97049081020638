import React, { useEffect, useRef, useState } from 'react';
import {createUseStyles, useTheme} from "react-jss";
import {Container, Grid, Header, Item, Segment} from 'semantic-ui-react';

const useStyles = createUseStyles({
    video: {
        display: 'flex',
        height: '100%',
        width: '100%'
    }
});

function SpanishVideosPage() {
    const theme = useTheme();
    const classes = useStyles({theme});
    const videoRef = useRef(null)
    const [video, setVideo] = useState(null)
    const [videos, setVideos] = useState(null)

    useEffect(() => {
        if (videos !== null)
            return
        const url = 'https://family-api.ericbulloch.com/spanish-videos'
        fetch(url, {
            method: 'GET',
            headers: {'Content-Type': 'application/json'}
        })
        .then(response => response.json())
        .then(json => {
            setVideos(json)
        })
    }, [videos, setVideos])

    if (videos === null)
        return 'No videos'
    const videoList = videos.filter(v => video === null || v.url !== video.url)

    return (
        <Grid>
            <Grid.Row>
                <Grid.Column width={12}>
                    {video && 
                        <Container>
                            <Header as='h3'>{video.name}</Header>
                            <video key={video.url} autoPlay className={classes.video} controls="controls" ref={videoRef}>
                                <source src={video.url} type="video/mp4" />
                            </video>
                        </Container>
                    }
                    {!video &&
                        <Container>
                            <Segment as='h3'>
                                Please select a video on the right
                            </Segment>
                        </Container>
                    }
                </Grid.Column>
                <Grid.Column width={3}>
                    <Item.Group>
                        {videoList.map(v => 
                            <Item key={v.url} as='a' onClick={() => setVideo(v)}>
                                <Item.Image size='tiny' src='https://react.semantic-ui.com/images/wireframe/image.png' />
                                <Item.Content>
                                    <Item.Header>{v.name}</Item.Header>
                                    <Item.Meta>Description about {v.name}</Item.Meta>
                                </Item.Content>
                            </Item>
                        )}
                    </Item.Group>
                </Grid.Column>
                <Grid.Column width={1}></Grid.Column>
            </Grid.Row>
        </Grid>
    )
}

export default SpanishVideosPage;