import React from 'react';
import {Grid, Icon, Input, Button} from 'semantic-ui-react';
import { createUseStyles, useTheme } from "react-jss";

const useStyles = createUseStyles({
    centerText: {
        "& input": {
            "text-align": "center !important"
        }
    }
})

function SpellingTestWord(props) {
    const theme = useTheme();
    const classes = useStyles({theme});
    const showError = props.correct === 0
    let label = null
    if (props.correct === true) {
        label = 'Correct'
    } else if (props.correct === false) {
        label = 'Try again'
    }
    return <Grid>
        <Grid.Row>
            <Grid.Column>
                <Input
                    className={classes.centerText}
                    onChange={e => props.onChange(e)}
                    onKeyDown={e => props.onKeyDown(e)}
                    ref={input => input && input.focus()}
                    error={showError}
                    label={label}
                    labelPosition="left corner"
                    size="massive"
                    value={props.value} />
            </Grid.Column>
        </Grid.Row>
        <Grid.Row>
            <Grid.Column>
                <Button
                    icon
                    labelPosition='left'
                    onClick={(e) => props.setPlay(e)}
                >
                    <Icon name='play' />
                    Play
                </Button>
                <Button onClick={e => props.onSubmit(e)} primary>Submit</Button>
            </Grid.Column>
        </Grid.Row>
    </Grid>
}

export default SpellingTestWord;