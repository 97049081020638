import axios from 'axios';
import React from 'react';

import SpellingTestWord from './SpellingTestWord';

import shuffle from '../functions/shuffle';
import AllDone from './AllDone';

const wordToSpeech = (word) => {
    const synthesis = window.speechSynthesis
    let utterance = new SpeechSynthesisUtterance(word)
    utterance.pitch = 0.6
    utterance.rate = 0.6
    synthesis.speak(utterance)
}

class SpellingTestPage extends React.Component {
    constructor(props) {
        super(props)
        const user = JSON.parse(window.localStorage.getItem('USER'));
        this.state = {
            correct: null,
            index: -1,
            inputText: '',
            user,
            words: []
        }
        this.onChange = this.onChange.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.setPlay = this.setPlay.bind(this);
    }
  
    componentDidMount() {
        if (!this.state.user) return;
        axios
            .get(`https://family-api.ericbulloch.com/word-search/`, { timeout: 5000 })
            .then(res => {
                const week = res.data.weeks[0];
                axios
                    .get(
                        `https://family-api.ericbulloch.com/word-search/${this.state.user}/${week}`,
                        { timeout: 5000 }
                    )
                    .then(res => {
                        const data = res.data;
                        let words = [];
                        for (let word in data.coordinates) {
                            words.push(word);
                        }
                        words = shuffle(words);
                        this.setState({
                            ...this.state,
                            correct: null,
                            index: 0,
                            inputText: '',
                            words
                        })
                    });
            });
    }
  
    onChange(e) {
        const inputText = e.target.value
        this.setState({
            ...this.state,
            inputText,
            correct: null
        })
    }
  
    onKeyDown(e) {
        if (e.keyCode === 13) {
            if (this.state.inputText !== '') this.onSubmit(e)
                return
        }
    }
  
    onSubmit(e) {
        const inputText = this.state.inputText
        const word = this.state.words[this.state.index]
        if (inputText === word) {
            const index = this.state.index + 1
            this.setState({
                ...this.state,
                correct: true,
                index,
                inputText: ''
            })
        } else {
            this.setState({
                ...this.state,
                correct: false
            })
        }
    }

    setPlay(e) {
        const word = this.state.words[this.state.index]
        if (word) {
            wordToSpeech(word)
        }
    }
  
    render() {
        if (this.state.words.length === 0) return ' '
        if (this.state.index >= this.state.words.length) {
            const url = `https://family-api.ericbulloch.com/finished/${this.state.user}/spelling_test/4edfc90b-27ef-4f5e-b2f9-edcf572f167f`
            fetch(url, {
                method: 'GET'
            })
            return <AllDone />
        }
        const word = this.state.words[this.state.index]
        return <SpellingTestWord
                    correct={this.state.correct}
                    onChange={this.onChange}
                    onKeyDown={this.onKeyDown}
                    onSubmit={this.onSubmit}
                    setPlay={this.setPlay}
                    value={this.state.inputText}
                    word={word} />
    }
}

export default SpellingTestPage;