import React, {useEffect, useRef, useState} from 'react';
import {createUseStyles, useTheme} from "react-jss";

const useStyles = createUseStyles({
    canvasStyle: {
        left: 0,
        position: 'absolute',
        top: 0
    }
})

function DrawingCanvas({clear, height, width}) {
    const theme = useTheme();
    const classes = useStyles({theme});
    const [lines, setLines] = useState([])
    const [currentLine, setCurrentLine] = useState([])
    const [creatingLine, setCreatingLine] = useState(false)
    const input = useRef(null);
    const onMouseDown = (event) => {
        if (!input.current)
            return
        setCreatingLine(true)
        const rect = input.current.getBoundingClientRect();
        const x = event.clientX - rect.left;
        const y = event.clientY - rect.top;
        const line = [{x, y}]
        setCurrentLine(line)
        const allLines = lines
        allLines.push(line)
        setLines(allLines)
    }
    const onMouseMove = (event) => {
        if (!creatingLine || !input.current)
            return
        var rect = input.current.getBoundingClientRect();
        const x = event.clientX - rect.left;
        const y = event.clientY - rect.top;
        let line = currentLine.concat([{x, y}])
        setCurrentLine(line)
        let allLines = lines
        allLines.pop()
        allLines.push(line)
        setLines(allLines)
    }
    const onMouseUp = (event) => {
        if (!creatingLine || !input.current)
            return
        setCreatingLine(false)
        setCurrentLine([])
    }
    const update = () => {
        if (!input.current || !lines)
            return
        const context = input.current.getContext("2d")
        context.clearRect(0, 0, input.current.width, input.current.height)
        context.font = "20px Courier"
        context.lineWidth = 2;
        context.strokeStyle = "black";
        for (let i = 0; i < lines.length; i++) {
            const line = lines[i]
            context.beginPath()
            let previousPoint = line[0]
            for (let j = 1; j < line.length; j++) {
                const point = line[j]
                context.moveTo(previousPoint.x, previousPoint.y)
                context.lineTo(point.x, point.y)
                context.stroke()
                previousPoint = point
            }
        }
    }
    
    useEffect(() => {
        update()
    })

    useEffect(() => {
        if (clear) {
            setCurrentLine([])
            setLines([])
        }
    }, [clear])
    return <canvas
        className={classes.canvasStyle}
        ref={input}
        height={height}
        onMouseDown={onMouseDown}
        onMouseMove={onMouseMove}
        onMouseUp={onMouseUp}
        width={width}
    />
}

export default DrawingCanvas;