import React from "react";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles(() => ({
	attribute: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		margin: "auto",
		width: "60%",
	},
}));

function EntityAttribute({ label, value }) {
	const classes = useStyles();
	return <div className={classes.attribute}>
			<span><strong>{label}</strong>:</span>
			<span>{value}</span>
		</div>
}

export default EntityAttribute;