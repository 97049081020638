import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import { Button } from "semantic-ui-react";
import EnemyCard from "./EnemyCard";
import HeroCard from "./HeroCard";

const useStyles = createUseStyles(() => ({
	row: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-evenly",
	},
	controls: {
		display: "flex",
		flexDirection: "column",
		gap: "4px",
	}
}));

const getMonster = (heroDefeated) => {
	let defeated = 0;
	if (heroDefeated.hasOwnProperty('Slime')) {
		defeated = heroDefeated['Slime'];
	}
	return {
		name: 'Slime',
		description: 'Basic enemy',
		hitPoints: 3,
		gold: 2,
		experience: 1,
		numberDefeated: defeated,
	}
}

function RpgGamePage() {
	const classes = useStyles();
	const [gameState, setGameState] = useState('battle');
	const [hero, setHero] = useState({
		name: 'Eric',
		description: 'Hero of Legend',
		hitPoints: 10,
		experience: 0,
		gold: 0,
		defeated: {}
	});
	const [enemy, setEnemy] = useState(getMonster(hero.defeated))
	const getRandomNumber = (min, max) => {
		const difference = (max + 1) - min;
		const value = Math.floor(Math.random() * difference);
		return min + value;
	}
	const onClickAttack = (hero, enemy) => {
		if (enemy.hitPoints < 1) {
			return;
		}
		const damage = getRandomNumber(1, 5);
		let enemyHitPoints = enemy.hitPoints - damage;
		let numberDefeated = enemy.numberDefeated;
		let heroGold = hero.gold;
		let heroExperience = hero.experience;
		let heroDefeated = hero.defeated;
		if (enemyHitPoints <= 0) {
			enemyHitPoints = 0
			numberDefeated += 1;
			heroGold += enemy.gold;
			heroExperience += enemy.experience;
			if (!heroDefeated.hasOwnProperty(enemy.name)) {
				heroDefeated[enemy.name] = 0;
			}
			heroDefeated[enemy.name] += 1;
		}
		setEnemy({...enemy, hitPoints: enemyHitPoints, numberDefeated: numberDefeated});
		let heroHitPoints = hero.hitPoints;
		if (enemyHitPoints > 0) {
			const enemyDamage = getRandomNumber(1, 3);
			heroHitPoints -= enemyDamage;
			if (heroHitPoints < 0) {
				heroHitPoints = 0;
			}
		}
		setHero({...hero, hitPoints: heroHitPoints, gold: heroGold, experience: heroExperience, defeated: heroDefeated});
	}
	const onClickMagic = () => {
		console.log('Pressed the magic button');
	}
	const onClickItem = () => {
		console.log('Pressed the item button');
	}
	const onClickRun = () => {
		console.log('Pressed the run button');
	}
	const onClickShop = () => {
		console.log('Pressed the shop button');
	}
	const onClickInn = () => {
		setHero({...hero, hitPoints: 10})
	}
	const onClickNewMonster = () => {
		const monster = getMonster(hero.defeated);
		setEnemy(monster)
	}
	return <div className={classes.row}>
			<div>
				<HeroCard hero={hero} />
				<div className={classes.controls}>
					{enemy.hitPoints > 0 &&
						<>
							<Button onClick={() => onClickAttack(hero, enemy)}>Attack</Button>
							<Button onClick={onClickMagic}>Magic</Button>
							<Button onClick={onClickItem}>Item</Button>
							<Button onClick={onClickRun}>Run</Button>
						</>
					}
					{enemy.hitPoints < 1 &&
						<>
							<Button onClick={onClickNewMonster}>New Monster</Button>
							<Button onClick={onClickInn}>Rest at Inn</Button>
							<Button onClick={onClickShop}>Go to Shop</Button>
						</>
					}
				</div>
			</div>
			<div>
				<EnemyCard enemy={enemy} />
				<div className={classes.controls}></div>
			</div>
		</div>
}

export default RpgGamePage;