import React from 'react';

import VerticalMathEquation from './VerticalMathEquation';
import InputBox from './InputBox';
import {Container} from 'semantic-ui-react';

function FullVerticalMathEquation({equation, setValue, submit}) {
    let cursorLeft = false
    if (['-', '+'].includes(equation.operator))
        cursorLeft = true

    return <Container>
        <VerticalMathEquation equation={equation} />
        <InputBox
            classes="text-right vertical-equation-answer-box"
            cursorLeft={cursorLeft}
            value={equation.answer}
            disabled={equation.solved}
            onChange={(event) => setValue(equation, event.target.value)}
            onKeyUp={(event) => {if (event.key === 'Enter') submit(equation, event.target.value)}} />
    </Container>
}

export default FullVerticalMathEquation;