import React, {useEffect, useState} from 'react';
import {Redirect} from 'react-router-dom';
import {createUseStyles, useTheme} from "react-jss";
import {
    Table,
    TableBody,
    TableCell,
    TableHeader,
    TableHeaderCell,
    TableRow,
} from "semantic-ui-react";

import useLocalStorage from '../functions/useLocalStorage';

const useStyles = createUseStyles({
    checkmarkImage: {
        'animation-name': 'answer-response',
        'animation-duration': '0.5s',
        display: 'initial !important',
        height: '100px',
        opacity: 0.5,
        position: 'absolute !important'
    },
    clearButton: {
        bottom: '50px',
        left: '50px',
        position: 'fixed'
    },
    copyButton: {
        bottom: '50px',
        left: '200px',
        position: 'fixed'
    },
    copiedProblem: {
        left: '150px',
        position: 'fixed',
        top: '65px',
        width: '200px'
    },
    horizontalMathContent: {
        display: "inline-block !important",
        'max-width': '800px'
    },
    mathEquationContainer: {
        display: 'flex',
        'justify-content': 'center',
        'margin-top': '15px',
        position: 'relative',
        width: 'auto !important'
    },
    missImage: {
        'animation-name': 'answer-response',
        'animation-duration': '0.5s',
        display: 'initial !important',
        height: '100px',
        opacity: 0.5,
        position: 'absolute !important'
    },
    submitButton: {
        position: 'fixed',
        bottom: '50px',
        right: '50px'
    },
    verticalMathContent: {
        display: "inline-block !important",
        'max-width': '200px'
    }
})

function StatusPage() {
    const theme = useTheme();
    const classes = useStyles({theme});
    const [username, ] = useLocalStorage('USER', '')
    const [statusList, setStatusList] = useState([]);

    function sortItems(a, b) {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        if (a.subject < b.subject) return -1;
        if (a.subject > b.subject) return 1;
        return 0;
    }
  
    function getRecords() {
        fetch("https://family-api.ericbulloch.com/status/4edfc90b-27ef-4f5e-b2f9-edcf572f167f", {
            method: "GET",
            headers: { "Content-Type": "application/json" },
        })
            .then((response) => response.json())
            .then((json) => {
                setStatusList(json.sort(sortItems));
            })
            .catch((error) => {
                console.error(error);
            });
    }

    useEffect(() => {
        getRecords();
    }, []);

    function renderItem(status) {
        return (
            <TableRow key={status.id}>
                <TableCell>{status.name}</TableCell>
                <TableCell>{status.subject}</TableCell>
                <TableCell>{status.date}</TableCell>
            </TableRow>
        );
    }
  
    if (!username || !["Merinda", "Eric"].includes(username))
        return <Redirect to="/" />

    return <div>
            {statusList.length > 0 && (
                <>
                    <Table celled striped>
                        <TableHeader>
                            <TableRow>
                                <TableHeaderCell>Name</TableHeaderCell>
                                <TableHeaderCell>Subject</TableHeaderCell>
                                <TableHeaderCell>Date</TableHeaderCell>
                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            {statusList.map((status) => {
                                return renderItem(status);
                            })}
                        </TableBody>
                    </Table>
                </>
            )}
            {statusList.length === 0 && "No status updates yet"}
    </div>
}

export default StatusPage;