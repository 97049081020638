import React from "react";
import { Link } from "react-router-dom";

import "../css/Navigation.css";

function Navigation({ username }) {
    let routes = [
        {
            text: "Home",
            url: "/",
        },
        {
            text: "Math",
            url: "/math",
        },
        {
            text: "Word Search",
            url: "/word-search",
        },
        {
            text: "Missing Letter",
            url: "/missing-letter",
        },
        {
            text: "Spelling Test",
            url: "/spelling-test",
        },
        {
            text: "Spanish Videos",
            url: "/spanish-videos",
        },
        {
            text: "Typing",
            url: "/typing",
        },
        {
            text: "Status",
            url: "/status",
        },
        {
            text: "Food List",
            url: "/food-list",
        },
    ];
    if (!username) routes = [routes[0]];
    else if (["Eric", "Merinda"].includes(username))
        routes = [routes[0], routes[routes.length - 2], routes[routes.length - 1]];
    else routes = routes.slice(0, -1);
    const getLink = (route, i, arr) => {
        if (arr.length - 1 === i) {
            return (
                <Link key={route.text} to={route.url}>
                    {route.text}
                </Link>
            );
        } else {
            return (
                <span key={route.text}>
                    <Link to={route.url}>{route.text}</Link> |{" "}
                </span>
            );
        }
    };
    return <nav>{routes.map((route, i, arr) => getLink(route, i, arr))}</nav>;
}

export default Navigation;
