import React from 'react';

import '../css/MissingLetter.css';
import AllDone from './AllDone';

function MissingLetter(props) {
    const {error, finished, edit, onKeyDown, onSubmit, username, word} = props;
    if (finished) {
        const url = `https://family-api.ericbulloch.com/finished/${username}/missing_letter/4edfc90b-27ef-4f5e-b2f9-edcf572f167f`
        fetch(url, {
            method: 'GET'
        })
        return <AllDone />
    }
    if (!word) return ' '
    let firstEmpty = -1;
    for (let i = 0; i < word.length; i++) {
        const key = `${i}`
        if (edit.hasOwnProperty(key) && edit[key] === '') {
            firstEmpty = i;
            break;
        }
    }

    const displayedWord = word.split('').map((letter, i) => {
        return (Object.keys(edit).includes(`${i}`)) ?
            <span key={i}>
                <input
                    maxLength="1"
                    onChange={() => {}}
                    onKeyDown={e => onKeyDown(e, `${i}`)}
                    ref={input => input && i === firstEmpty && input.focus()}
                    size="1"
                    type="text"
                    value={edit[i]} />
            </span>
            :
            <span key={i}>{letter}</span>;
    })
    const style = {
        color: 'red'
    };
    return <>
        <h1>What letter is missing?</h1>
        {error &&
            <span style={style}>Please try again</span>
        }
        <div className="displayed-word">
            {displayedWord}
        </div>
        <button className='btn-lg btn-success' onClick={e => onSubmit(e)}>Submit</button>
    </>
}

export default MissingLetter;