import React from "react";

class ActualCanvasComponent extends React.Component {
    constructor(props) {
      super(props);
      this.onMouseDown = this.onMouseDown.bind(this);
      this.onMouseMove = this.onMouseMove.bind(this);
      this.onMouseUp = this.onMouseUp.bind(this);
      this.allFound = this.allFound.bind(this);
    }
  
    onMouseDown(event) {
      var rect = this.refs.canvas.getBoundingClientRect();
      const x = event.clientX - rect.left;
      const y = event.clientY - rect.top;
      this.props.onMouseDown(event.button, x, y);
    }
  
    onMouseMove(event) {
      var rect = this.refs.canvas.getBoundingClientRect();
      const x = event.clientX - rect.left;
      const y = event.clientY - rect.top;
      this.props.onMouseMove(event.button, x, y);
    }
  
    onMouseUp(event) {
      var rect = this.refs.canvas.getBoundingClientRect();
      this.props.onMouseUp(event.clientX - rect.left, event.clientY - rect.top);
    }
  
    componentDidMount() {
      this.updateCanvas();
    }
  
    componentDidUpdate() {
      this.updateCanvas();
    }
  
    allFound(arr) {
      return arr.every(v => v.found === true);
    }
  
    updateCanvas() {
      if (!this.props.board) {
        return;
      }
      const ctx = this.refs.canvas.getContext("2d");
      ctx.clearRect(0, 0, this.refs.canvas.width, this.refs.canvas.height);
      const fontFamily = 'Courier'
      const fontSize = 20;
      ctx.font = `${fontSize}px ${fontFamily}`;
      ctx.lineWidth = 1;
      ctx.strokeStyle = "black";
      const height = this.props.board.length * this.props.cellDimension;
      const width = this.props.board[0].length * this.props.cellDimension;
      for (let i = 0; i <= this.props.board.length; i++) {
        ctx.beginPath();
        ctx.moveTo(i * this.props.cellDimension, 0);
        ctx.lineTo(i * this.props.cellDimension, height);
        ctx.stroke();
      }
      ctx.lineWidth = 1;
      ctx.strokeStyle = "black";
      for (let i = 0; i <= this.props.board[0].length; i++) {
        ctx.beginPath();
        ctx.moveTo(0, i * this.props.cellDimension);
        ctx.lineTo(width, i * this.props.cellDimension);
        ctx.stroke();
      }
      let x = this.props.cellDimension / 2;
      let y = this.props.cellDimension / 2;
      const spacing = this.props.cellDimension;
      for (let i = 0; i < this.props.board.length; i++) {
        const row = this.props.board[i];
        for (let j = 0; j < row.length; j++) {
          const character = row[j];
          const xPosition = x - fontSize / 4;
          const yPosition = y + fontSize / 4;
          ctx.fillText(character, xPosition, yPosition);
          x += spacing;
        }
        x = spacing / 2;
        y += spacing;
      }

      x = this.props.cellDimension / 2;
      y = this.props.cellDimension / 2;
      for (let i = 0; i < this.props.words.length; i++) {
        const word = this.props.words[i];
        if (word)
        {
          ctx.fillText(word.text, spacing * (this.props.board.length + 2), y);
          if (word.found) {
            ctx.beginPath();
            ctx.strokeStyle = word.color;
            ctx.lineWidth = fontSize;
            ctx.lineCap = "round";
            const startX = spacing * (this.props.board.length + 2)
            ctx.moveTo(startX, y - fontSize / 4);
            const lineLength = (word.text.length * spacing / 4) + ((word.text.length - 2) * spacing / 16)
            ctx.lineTo(startX + lineLength, y - fontSize / 4);
            ctx.stroke();
          }
        }
        x = spacing / 2;
        y += spacing * .75;
      }


      for (const word of this.props.words) {
        if (word && word.found) {
          ctx.beginPath();
          ctx.strokeStyle = word.color;
          ctx.lineWidth = fontSize;
          ctx.lineCap = "round";
          ctx.moveTo(
            word.coordinates[0] * spacing + spacing / 2,
            word.coordinates[1] * spacing + spacing / 2
          );
          ctx.lineTo(
            word.coordinates[2] * spacing + spacing / 2,
            word.coordinates[3] * spacing + spacing / 2
          );
          ctx.stroke();
        }
      }
      if (this.props.startingClick && this.props.currentPosition) {
        ctx.beginPath();
        ctx.strokeStyle = 'rgba(105, 105, 105, 0.4)'; // gray
        ctx.lineWidth = fontSize;
        ctx.lineCap = 'round';
        ctx.moveTo(
          this.props.startingClick.x,
          this.props.startingClick.y,
        );
        ctx.lineTo(
          this.props.currentPosition.x,
          this.props.currentPosition.y
        );
        ctx.stroke();
      }
      if (this.allFound(this.props.words)) {
        const url = `https://family-api.ericbulloch.com/finished/${this.props.username}/word_search/4edfc90b-27ef-4f5e-b2f9-edcf572f167f`
        fetch(url, {
            method: 'GET'
        })
        const fontSize = 40;
        ctx.fillStyle = "black";
        ctx.fillRect(40, 20, 200, fontSize);
        ctx.fillStyle = "white";
        ctx.font = `${fontSize}px ${fontFamily}`;
        ctx.strokeStyle = "white";
        ctx.fillText("YOU WIN!", 50, 50);
      }
    }
  
    render() {
      return (
        <canvas
          onMouseDown={this.onMouseDown}
          onMouseMove={this.onMouseMove}
          onMouseUp={this.onMouseUp}
          ref="canvas"
          width={this.props.width}
          height={this.props.height}
        />
      );
    }
}

export default ActualCanvasComponent;