import axios from "axios";
import React from "react";

import MissingLetter from "./MissingLetter";

class MissingLetterPage extends React.Component {
    constructor(props) {
        super(props);
        const user = JSON.parse(window.localStorage.getItem("USER"));
        this.state = {
            allWords: [],
            edit: {},
            error: false,
            finished: false,
            letter: null,
            timesCompleted: 0,
            user: user,
            words: [],
            word: null,
        };
        this.onKeyDown = this.onKeyDown.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.setNewWord = this.setNewWord.bind(this);
    }

    componentDidMount() {
        if (!this.state.user) return;
        axios
            .get(`https://family-api.ericbulloch.com/word-search/`, {
                timeout: 5000,
            })
            .then((res) => {
                const week = res.data.weeks[0];
                axios
                    .get(
                        `https://family-api.ericbulloch.com/word-search/${this.state.user}/${week}`,
                        { timeout: 5000 }
                    )
                    .then((res) => {
                        const data = res.data;
                        let words = [];
                        for (let word in data.coordinates) {
                            words.push(word);
                        }
                        this.setState(
                            {
                                ...this.state,
                                words,
                                allWords: words,
                            },
                            this.setNewWord
                        );
                    });
            });
    }

    onKeyDown(e, i) {
        if (e.keyCode === 13) {
            let hasValues = true;
            for (const [key, value] of Object.entries(this.state.edit)) {
                if (value === "") {
                    hasValues = false;
                    break;
                }
            }
            if (hasValues) this.onSubmit(e);
            return;
        }
        let inputLetter = e.key;
        if (inputLetter === "Backspace") inputLetter = "";
        if (
            inputLetter === "" ||
            (inputLetter.length === 1 && inputLetter.match(/[a-z]/i))
        ) {
            const edit = this.state.edit;
            edit[i] = inputLetter;
            this.setState({
                ...this.state,
                edit,
            });
        }
    }

    onSubmit(e) {
        let allCorrect = true;
        for (const [key, value] of Object.entries(this.state.edit)) {
            if (value !== this.state.word[key]) {
                allCorrect = false;
                break;
            }
        }
        if (allCorrect) {
            this.setNewWord();
        } else {
            this.setState({
                ...this.state,
                error: true,
            });
        }
    }

    setNewWord() {
        let words = this.state.words;
        let count = this.state.timesCompleted;
        if (words.length === 0) {
            count++;
            words = this.state.allWords;
        }
        const word = words[Math.floor(Math.random() * words.length)];
        words = words.filter((value, i, arr) => value !== word);
        let index = -1;
        let edit = {};
        if (word) {
            while (true) {
                index = Math.floor(Math.random() * word.length);
                if (word[index].match(/[a-z]/i)) {
                    if (!edit.hasOwnProperty(index)) {
                        edit[index] = "";
                    }
                    if (word.length < 3) break;
                    if (Object.keys(edit).length === 2) break;
                }
            }
        }
        this.setState({
            ...this.state,
            error: false,
            finished: count === 3 ? true : false,
            index,
            edit,
            inputLetter: "",
            timesCompleted: count,
            words,
            word,
        });
    }

    render() {
        return (
            <MissingLetter
                error={this.state.error}
                finished={this.state.finished}
                index={this.state.index}
                edit={this.state.edit}
                inputLetter={this.state.inputLetter}
                onKeyDown={this.onKeyDown}
                onSubmit={this.onSubmit}
                username={this.state.user}
                word={this.state.word}
            />
        );
    }
}

export default MissingLetterPage;
