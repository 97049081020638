import React from 'react';

function InputBox({classes, value, disabled, onChange, onKeyUp, cursorLeft}) {
    return <input
        ref={input => {
            if (input) {
                input.focus()
                if (cursorLeft)
                    input.setSelectionRange(0, 0)
            }
        }}
        className={classes}
        type="text"
        value={value}
        disabled={disabled}
        onChange={onChange}
        onKeyUp={onKeyUp} />
}

export default InputBox;