import React from "react";
import { createUseStyles } from "react-jss";
import { Card } from "semantic-ui-react";

import EntityAttribute from "./EntityAttribute";

const useStyles = createUseStyles(() => ({
	attributes: {
		display: "flex",
		flexDirection: "column",
		alignItems: "flex-start",
	},
}));

function EnemyCard({ enemy }) {
	const classes = useStyles();
	return <Card>
			<Card.Content>
				<Card.Header>{enemy.name}</Card.Header>
				<Card.Meta>{enemy.description}</Card.Meta>
				<Card.Description className={classes.attributes}>
					<EntityAttribute label='Hit Points' value={enemy.hitPoints} />
					<EntityAttribute label='Number Defeated' value={enemy.numberDefeated} />
				</Card.Description>
			</Card.Content>
		</Card>
}

export default EnemyCard;