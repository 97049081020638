import axios from 'axios';
import React from 'react';
import {Redirect} from "react-router-dom";

import ActualCanvasComponent from './ActualCanvasComponent';

import shuffle from '../functions/shuffle';

import '../css/WordSearchPage.css';

class WordSearchPage extends React.Component {
    constructor(props) {
      super(props);
      const user = JSON.parse(window.localStorage.getItem('USER'));
      this.state = {
        cellDimension: 40,
        currentPosition: null,
        startingClick: null,
        user: user,
        words: []
      };
      this.colors = [
        "rgba(255, 0, 0, 0.4)",
        "rgba(0, 0, 255, 0.4)",
        "rgba(0, 255, 0, 0.4)",
        "rgba(106, 13, 173, 0.4)", // purple
        "rgba(255, 255, 0, 0.4)", // "yellow"
        "rgba(255, 165, 0, 0.4)", // "orange"
        "rgba(150, 75, 0, 0.4)" // "brown"
      ];
      this.shuffleIndex = -1;
      this.finished = this.finished.bind(this);
      this.getColor = this.getColor.bind(this);
      this.normalize = this.normalize.bind(this);
      this.onMouseDown = this.onMouseDown.bind(this);
      this.onMouseMove = this.onMouseMove.bind(this);
      this.onMouseUp = this.onMouseUp.bind(this);
    }
  
    getColor() {
      if (this.shuffleIndex === -1 || this.shuffleIndex >= this.colors.length) {
        this.colors = shuffle(this.colors);
        this.shuffleIndex = 0;
      }
      const index = this.shuffleIndex;
      this.shuffleIndex++;
      return this.colors[index];
    }
  
    componentDidMount() {
      if (!this.state.user) return;
      axios
        .get(`https://family-api.ericbulloch.com/word-search/`, { timeout: 5000 })
        .then(res => {
          const week = res.data.weeks[0];
          axios
            .get(
              `https://family-api.ericbulloch.com/word-search/${this.state.user}/${week}`,
              { timeout: 5000 }
            )
            .then(res => {
              const data = res.data;
              let words = [];
              let id = 1;
              for (let word in data.coordinates) {
                const coord = data.coordinates[word];
                const coordinate = [coord[1], coord[0], coord[3], coord[2]];
                const value = {
                  text: word,
                  found: false,
                  coordinates: coordinate,
                  color: this.getColor(),
                  id
                };
                id++;
                words.push(value);
              }
              this.setState({
                ...this.state,
                board: data.board,
                words
              });
            });
        });
    }
  
    finished() {
      return this.state.words.every(function(word) { return word.found });
    }
  
    checkClick(possible) {
      const other = JSON.stringify([
        possible[2],
        possible[3],
        possible[0],
        possible[1]
      ]);
      const possibleString = JSON.stringify(possible);
      let words = this.state.words;
      for (const text in words) {
        const word = words[text];
        if (word.found) continue;
        const solution = JSON.stringify(words[text].coordinates);
        if (solution === other || solution === possibleString) {
          const color = this.getColor();
          word.color = color;
          word.found = true;
          break;
        }
      }
      this.setState({
        ...this.state,
        words
      });
    }
  
    normalize(coordinate) {
      return Math.floor(coordinate / this.state.cellDimension);
    }
  
    onMouseDown(button, x, y) {
      if (this.finished() || button !== 0) return;
      this.setState({
        ...this.state,
        startingClick: {x: x, y: y}
      });
    }
  
    onMouseMove(button, x, y) {
      if (this.finished() || !this.state.startingClick || button !== 0) return
      this.setState({
        ...this.state,
        currentPosition: {x: x, y: y}
      });
    }
  
    onMouseUp(x, y) {
      if (this.state.startingClick == null) return;
      const possible = [
        this.normalize(this.state.startingClick.x),
        this.normalize(this.state.startingClick.y),
        this.normalize(x),
        this.normalize(y)
      ];
      this.checkClick(possible);
      this.setState({
        ...this.state,
        currentPosition: null,
        startingClick: null
      })
    }
  
    render() {
      if (!this.state.user) return <Redirect to="/" />;
      if (!this.state.board) return <div></div>;
      return (
        <div className="word-search-content">
          <ActualCanvasComponent
            board={this.state.board}
            cellDimension={this.state.cellDimension}
            currentPosition={this.state.currentPosition}
            height={this.state.cellDimension * (this.state.board[0].length + 3)}
            onMouseDown={this.onMouseDown}
            onMouseMove={this.onMouseMove}
            onMouseUp={this.onMouseUp}
            startingClick={this.state.startingClick}
            username={this.state.user}
            width={this.state.cellDimension * (this.state.board.length + 8)}
            words={this.state.words}
          />
        </div>
      );
    }
}

export default WordSearchPage;