import React from 'react';
import {createUseStyles, useTheme} from "react-jss";

import HorizontalComparisonMathEquation from './HorizontalComparisonMathEquation';
import InputBox from './InputBox';

const useStyles = createUseStyles({
    horizontalEquation: {
        'padding-top': '100px'
    }
})

function HorizontalMathEquation({equation, setValue, submit}) {
    const theme = useTheme();
    const classes = useStyles({theme});
    if ([">", "=", "<"].includes(equation.operator))
        return <HorizontalComparisonMathEquation
            equation={equation}
            setValue={setValue}
            submit={submit} />
    return <div className={classes.horizontalEquation}>
        <span className="horizontal-operand">{equation.operands.join(` ${equation.displayOperator} `)} = </span>
        <InputBox
            classes="text-center horizontal-equation-answer-box"
            cursorLeft={false}
            value={equation.answer}
            disabled={equation.solved}
            onChange={(event) => setValue(equation, event.target.value)}
            onKeyUp={(event) => {if (event.key === 'Enter') submit(equation, event.target.value)}} />
    </div>
}

export default HorizontalMathEquation;