import axios from "axios";
import React, { useCallback, useState } from "react";
import { useEffect } from "react";
import { createUseStyles } from "react-jss";
import shuffle from "../functions/shuffle";
import AllDone from "./AllDone";

const useStyles = createUseStyles(() => ({
    row: {
        display: "flex",
        flexDirection: "row",
        height: "75vh",
        justifyContent: "space-evenly",
        position: "relative",
    },
    topRow: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-evenly",
    },
    textBox: {
        fontSize: "72px",
        fontFamily: "Courier",
        position: "fixed",
    },
    normal: {
        textDecoration: "normal",
    },
    underline: {
        textDecoration: "underline",
        textUnderlineOffset: "16px",
    },
}));

function TypingGamePage() {
    const classes = useStyles();
    const [words, setWords] = useState([]);
    const [wordIndex, setWordIndex] = useState(-1);
    const [word, setWord] = useState({
        letters: [],
        colors: [],
        index: 0,
    });
    const [finished, setFinished] = useState(false);
    const startingPosition = 100;
    const [yPosition, setYPosition] = useState(startingPosition);

    const getNewWord = (words, wordIndex) => {
        const index = wordIndex + 1;
        if (index === words.length) {
            setFinished(true);
            return;
        }
        const word = {
            letters: words[index].split(""),
            colors: Array(words[index].length).fill("black"),
            index: 0,
        };
        setWord(word);
        setWordIndex(index);
        setYPosition(startingPosition);
    };

    const keyPressHandler = useCallback(
        (event) => {
            if (word.letters.length === 0) return;
            if (event.key.length > 1) return;
            const color =
                event.key === word.letters[word.index] ? "green" : "red";
            const colors = [...word.colors];
            colors[word.index] = color;
            const index = color === "green" ? word.index + 1 : word.index;
            setWord({
                ...word,
                index: index,
                colors: colors,
            });

            if (index === word.letters.length) {
                // sleep here for 1 second would be nice
                getNewWord(words, wordIndex);
            }
        },
        [word, words, wordIndex]
    );

    useEffect(() => {
        document.addEventListener("keyup", keyPressHandler);
        return () => document.removeEventListener("keyup", keyPressHandler);
    }, [keyPressHandler]);

    useEffect(() => {
        const interval = setInterval(() => {
            let newPosition = yPosition + 1;
            if (newPosition > 700) {
                newPosition = startingPosition;
                const newWord = {
                    ...word,
                    colors: Array(word.length).fill("black"),
                    index: 0,
                };
                setWord(newWord);
            }
            setYPosition(newPosition);
        }, 50);

        return () => clearInterval(interval);
    }, [yPosition, word]);

    useEffect(() => {
        async function getData() {
            const user = JSON.parse(window.localStorage.getItem("USER"));
            let data = await axios.get(
                `https://family-api.ericbulloch.com/word-search/`,
                { timeout: 5000 }
            );
            const week = data.data.weeks[0];
            data = await axios.get(
                `https://family-api.ericbulloch.com/word-search/${user}/${week}`,
                { timeout: 5000 }
            );
            let words = [];
            const repetitions = 10;
            for (let word in data.data.coordinates) {
                for (let i = 0; i < repetitions; i++) {
                    words.push(word);
                }
            }
            words = shuffle(words);
            setWords(words);
            getNewWord(words, -1);
        }
        getData();
    }, []);

    if (finished) {
        return <AllDone />;
    }

    return (
        <div className={classes.row}>
            <div className={classes.topRow}>
                <span>
                    Word {wordIndex + 1} of {words.length}
                </span>
            </div>
            <div
                className={classes.textBox}
                contentEditable={false}
                onKeyDown={(e) => console.log(e)}
                style={{ top: yPosition }}
            >
                {word &&
                    word.letters.map((l, i) => {
                        const color = word.colors[i];
                        let style = { color: color };
                        const className =
                            i === word.index
                                ? classes.underline
                                : classes.normal;
                        return (
                            <span
                                className={className}
                                style={style}
                                key={`word-${i}`}
                            >
                                {l}
                            </span>
                        );
                    })}
            </div>
        </div>
    );
}

export default TypingGamePage;
