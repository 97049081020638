import React from 'react';
import {createUseStyles, useTheme} from "react-jss";

import {Container, Divider} from 'semantic-ui-react';

const useStyles = createUseStyles({
    verticalOperand: {
        'font-size': '3em',
        height: '1em',
        'line-height': '1em',
        'text-align': 'right',
        width: '100%'
    }
})

function VerticalMathEquation({equation}) {
    const theme = useTheme();
    const classes = useStyles({theme});
    const operator = equation.displayOperator

    const getLine = (operand, i) => {
        if (i === 0) {
            return <div key={i} className={classes.verticalOperand}>{operand}</div>
        } else {
            return <div key={i} className={classes.verticalOperand}>{operator} {operand}</div>
        }
    }
    
    return <Container>
        {equation.operands.map((operand, i) =>
            getLine(operand, i)
        )}
        <Divider />
    </Container>
}

export default VerticalMathEquation;