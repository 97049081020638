import React from "react";
import { createUseStyles } from "react-jss";
import { Card } from "semantic-ui-react";

import EntityAttribute from "./EntityAttribute";

const useStyles = createUseStyles(() => ({
	attributes: {
		display: "flex",
		flexDirection: "column",
		alignItems: "flex-start",
	},
}));

function HeroCard({ hero }) {
	const classes = useStyles();
	return <Card>
			<Card.Content>
				<Card.Header>{hero.name}</Card.Header>
				<Card.Meta>{hero.description}</Card.Meta>
				<Card.Description className={classes.attributes}>
					<EntityAttribute label='Hit Points' value={hero.hitPoints} />
					<EntityAttribute label='Gold' value={hero.gold} />
					<EntityAttribute label='Experience' value={hero.experience} />
				</Card.Description>
			</Card.Content>
		</Card>
}

export default HeroCard;