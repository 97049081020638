import React from 'react';

function HorizontalComparisonMathEquation({equation, setValue, submit}) {
    return <div className="horizontal-comparison-equation">
        <span className="horizontal-operand">{equation.operands[0]} </span>
        <select
            ref={input => input && input.focus()}
            name="answer"
            className="horizontal-equation-select-box"
            value={equation.answer}
            onChange={(event) => setValue(equation, event.target.value)}
            onKeyPress={(event) => {if (event.key === 'Enter') submit(equation, event.target.value)}}
            disabled={equation.solved}
        >
            <option value=""> </option>
            <option value="<">&lt;</option>
            <option value="=">=</option>
            <option value=">">&gt;</option>
        </select>
        <span className="horizontal-operand"> {equation.operands[1]}</span>
    </div>
}

export default HorizontalComparisonMathEquation;