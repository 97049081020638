import React from 'react';

import FullVerticalMathEquation from './FullVerticalMathEquation';
import HorizontalMathEquation from './HorizontalMathEquation';

function MathEquation({equation, setValue, submit}) {
    if (equation.direction.toLowerCase() === 'vertical') return <FullVerticalMathEquation equation={equation} setValue={setValue} submit={submit} />
    return <HorizontalMathEquation
        equation={equation}
        setValue={setValue}
        submit={submit} />
}

export default MathEquation;