import React, { useState, useRef } from "react";

import { Button, Container, Dropdown, Input, Message } from "semantic-ui-react";

import "../css/Login.css";

const checkUser = (username, password, updateUser, setErrorMessage) => {
    const url = "https://family-api.ericbulloch.com/authenticate/";
    fetch(url, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ username, password }),
    })
        .then((response) => response.json())
        .then((json) => {
            if (json.status === "ok") updateUser(username);
            setErrorMessage(json.message);
            return json;
        })
        .catch((error) => {
            console.log(error);
        });
};

const users = [
    {
        key: "Ashardaylon",
        text: "Ashardaylon",
        value: "Ashardaylon",
    },
    {
        key: "Jesslynn",
        text: "Jesslynn",
        value: "Jesslynn",
    },
    {
        key: "Zaiden",
        text: "Zaiden",
        value: "Zaiden",
    },
    {
        key: "Elodie",
        text: "Elodie",
        value: "Elodie",
    },
    {
        key: "Merinda",
        text: "Merinda",
        value: "Merinda",
    },
    {
        key: "Eric",
        text: "Eric",
        value: "Eric",
    },
];

function Login({ updateUser, username }) {
    const [errorMessage, setErrorMessage] = useState("");
    const [edit, setEdit] = useState({ username: "", password: "" });

    // camera login section
    const [showCamera, setShowCamera] = useState(false);
    const [stream, setStream] = useState(null);
    const videoRef = useRef(null);
    const canvasRef = useRef(null);
    const runCamera = () => {
        setShowCamera(true);
        navigator.mediaDevices
            .getUserMedia({ video: true })
            .then((stream) => {
                setStream(stream);
            })
            .catch(() => {
                setErrorMessage("Could not find a camera");
                setShowCamera(false);
            });
    };
    const takePicture = () => {
        if (videoRef && videoRef.current && canvasRef && canvasRef.current) {
            const dimensions = videoRef.current.getBoundingClientRect();
            canvasRef.current.width = dimensions.width;
            canvasRef.current.height = dimensions.height;
            canvasRef.current
                .getContext("2d")
                .drawImage(
                    videoRef.current,
                    0,
                    0,
                    dimensions.width,
                    dimensions.height
                );
        }
    };

    if (stream && videoRef && videoRef.current) {
        videoRef.current.srcObject = stream;
    }

    if (username) {
        if (edit.username || edit.password)
            setEdit({ username: "", password: "" });
        return (
            <div className="login-content">
                <strong>Hello {username}</strong>
                {"  "}
                <Button onClick={() => updateUser("")}>Log Out</Button>
            </div>
        );
    }
    return (
        <div className="login-content">
            <h3>Who are you?</h3>
            <Dropdown
                placeholder="Who are you?"
                selection
                options={users}
                value={edit.username}
                onChange={(e, data) =>
                    setEdit({ ...edit, username: data.value })
                }
            />
            <Input
                placeholder="Password..."
                type="password"
                value={edit.password}
                onChange={(e) => setEdit({ ...edit, password: e.target.value })}
            />
            <Button
                onClick={() =>
                    checkUser(
                        edit.username,
                        edit.password,
                        updateUser,
                        setErrorMessage
                    )
                }
            >
                Submit
            </Button>
            {errorMessage && <Message error header={errorMessage} />}

            {/* camera log in section */}
            {false && (
                <Container>
                    {!showCamera && (
                        <Button onClick={() => runCamera()}>
                            Allow Camera
                        </Button>
                    )}
                    {showCamera && (
                        <>
                            <video autoPlay ref={videoRef} />
                            <canvas ref={canvasRef} />
                            <Button onClick={takePicture}>Take Picture</Button>
                        </>
                    )}
                </Container>
            )}
        </div>
    );
}

export default Login;
