import React, {useEffect, useState} from 'react';
import {Redirect} from 'react-router-dom';
import {createUseStyles, useTheme} from "react-jss";
import {Button, Image} from 'semantic-ui-react';

import AllDone from './AllDone';
import DrawingCanvas from './DrawingCanvas';
import MathEquation from './MathEquation';
import VerticalMathEquation from './VerticalMathEquation';

import shuffle from '../functions/shuffle';
import useLocalStorage from '../functions/useLocalStorage';

import '../css/MathPage.css';

import Checkmark from '../images/checkmark.svg';
import RedX from '../images/redX.svg';

const useStyles = createUseStyles({
    checkmarkImage: {
        'animation-name': 'answer-response',
        'animation-duration': '0.5s',
        display: 'initial !important',
        height: '100px',
        opacity: 0.5,
        position: 'absolute !important'
    },
    clearButton: {
        bottom: '50px',
        left: '50px',
        position: 'fixed'
    },
    copyButton: {
        bottom: '50px',
        left: '200px',
        position: 'fixed'
    },
    copiedProblem: {
        left: '150px',
        position: 'fixed',
        top: '65px',
        width: '200px'
    },
    horizontalMathContent: {
        display: "inline-block !important",
        'max-width': '800px'
    },
    mathEquationContainer: {
        display: 'flex',
        'justify-content': 'center',
        'margin-top': '15px',
        position: 'relative',
        width: 'auto !important'
    },
    missImage: {
        'animation-name': 'answer-response',
        'animation-duration': '0.5s',
        display: 'initial !important',
        height: '100px',
        opacity: 0.5,
        position: 'absolute !important'
    },
    submitButton: {
        position: 'fixed',
        bottom: '50px',
        right: '50px'
    },
    verticalMathContent: {
        display: "inline-block !important",
        'max-width': '200px'
    }
})

function getSolution(operator, operands) {
    const comparisonOperators = ['=', '<', '>'];
    if (operator === 'x')
      return operands.reduce((a,b) => a * b);
    else if (operator === '+')
      return operands.reduce((a,b) => a + b);
    else if (operator === '-')
      return operands.reduce((a,b) => a - b);
    else if (operator === '/')
      return operands.reduce((a,b) => a / b);
    else if (comparisonOperators.includes(operator)) {
      return operator;
    }
    return -100;
}

function MathPage() {
    const theme = useTheme();
    const classes = useStyles({theme});
    const [username, ] = useLocalStorage('USER', '')
    const [storageProblems, setStorageProblems] = useLocalStorage(`PROBLEMS_${username}`, '')
    const [problemsIndex, setProblemsIndex] = useState(0)
    const [problems, setProblems] = useState([])
    const [showError, setShowError] = useState(false)
    const [showCopiedProblem, setShowCopiedProblem] = useState(false)
    const [finished, setFinished] = useState(false)
    const [clearCanvas, setClearCanvas] = useState(false)
    const [isAnimation, setIsAnimation] = useState(false)
    const [height, setHeight] = useState(window.innerHeight)
    const [width, setWidth] = useState(window.innerWidth)
    const animationTimeInMs = 500
  
    const nextEquation = () => {
        let index = problemsIndex
        index++
        if (index >= problems.length) index = 0
        setProblemsIndex(index)
    }

    const clear = () => {
        setClearCanvas(true)
    }

    const copyProblem = () => {
        setShowCopiedProblem(!showCopiedProblem)
    }
  
    const submit = () => {
        let equation = problems[problemsIndex]
        equation.submitted = true;
        if (!isNaN(equation.answer)) {
            if (equation.answer !== '' && equation.solution === Number(equation.answer))
                equation.solved = true
        } else {
            if (equation.solution === equation.answer)
                equation.solved = true
        }
        let equations = [...problems]
        equations[problemsIndex] = equation
        setStorageProblems(JSON.stringify(equations))
        setProblems(equations)
        setIsAnimation(true)
        if (!equation.solved) {
            setShowError(true)
        } else {
            setClearCanvas(true)
            setShowCopiedProblem(false)
        }
        if (problems.every(p => p.solved === true)) {
            setFinished(true)
            setStorageProblems('')
        }
    }

    const integerFilter = (value) => {
        return /^\d*$/.test(value); // Allow digits using a RegExp
    }
  
    const setValue = (equation, value) => {
        const comparisons = [">", "=", "<"];
        let equations = [...problems]
        if (value === '' || (comparisons.includes(equation.solution) && comparisons.includes(value)) || integerFilter(value))
            equation.answer = value.trim()
        equations[equation.index] = equation
        setProblems(equations)
    }
  
    useEffect(() => {
        if (storageProblems.length > 0)
            return
        const url = `https://family-api.ericbulloch.com/equations/${username}`
        fetch(url, {
            method: 'GET',
            headers: {'Content-Type': 'application/json'}
        })
        .then(response => response.json())
        .then(json => {
            let equations = []
            json.equations.map((equation) =>
                equations.push(
                {
                    displayOperator: (equation.operator === '/') ? '÷' : equation.operator,
                    operator: equation.operator,
                    operands: equation.operands,
                    direction: equation.direction,
                    solution: getSolution(equation.operator, equation.operands),
                    answer: '',
                    solved: false,
                    submitted: false,
                }
                )
            )
            setStorageProblems(JSON.stringify(equations))
        })
    }, [storageProblems, setStorageProblems, username])
  
    useEffect(() => {
        if (!storageProblems)
            return
        let equations = JSON.parse(storageProblems)
        if (equations.length > 0 && equations[0].hasOwnProperty('index') && equations[0].index === 0)
            return
        equations = shuffle(equations)
        equations.map((equation, index) => equation.index = index)
        setStorageProblems(JSON.stringify(equations))
    }, [storageProblems, setStorageProblems])
  
    useEffect(() => {
        if (!storageProblems)
            return
        setProblems(JSON.parse(storageProblems))
    }, [storageProblems, setProblems])
  
    useEffect(() => {
        let problem = problems[problemsIndex]
        if (problem == null)
            return
        if (showError) {
            setTimeout(function() {
                setIsAnimation(false)
                setShowError(false)
            }, animationTimeInMs)
        } else if (problem.solved) {
            setTimeout(function() {
                setIsAnimation(false)
                nextEquation()
            }, animationTimeInMs)
        }
    })

    useEffect(() => {
        function handleResize() {
            setHeight(window.innerHeight)
            setWidth(window.innerWidth)
        }
        window.addEventListener('resize', handleResize)
        return _ => {window.removeEventListener('resize', handleResize)}
    })

    useEffect(() => {
        if (clearCanvas)
            setClearCanvas(false)
    }, [clearCanvas])
  
    if (!username)
        return <Redirect to="/" />
    if (problems.length === 0)
        return 'Loading...'
    if (finished) {
        const url = `https://family-api.ericbulloch.com/finished/${username}/math/4edfc90b-27ef-4f5e-b2f9-edcf572f167f`
        fetch(url, {
            method: 'GET'
        })
        return <AllDone />
    }
    let firstUnsolvedProblem = problems.find((problem) => !problem.solved)
    if (!isAnimation && firstUnsolvedProblem && firstUnsolvedProblem.index && firstUnsolvedProblem.index !== problemsIndex) {
        setProblemsIndex(firstUnsolvedProblem.index)
    }
    const equation = problems[problemsIndex]
    const mathContentClass = (equation.direction === 'vertical') ? classes.verticalMathContent : classes.horizontalMathContent
    const showCopyButton = equation.direction === 'horizontal' && ['+', '-'].includes(equation.operator) && !showCopiedProblem
    return <div className={mathContentClass}>
        <strong>Problem {equation.index + 1} of {problems.length}</strong>
        <DrawingCanvas height={height} width={width} clear={clearCanvas} />
        <div className={classes.mathEquationContainer}>
            {equation.solved && <Image src={Checkmark} className={classes.checkmarkImage} />}
            {showError && <Image src={RedX} className={classes.missImage} />}
            <MathEquation equation={equation} setValue={setValue} submit={submit} />
        </div>
        {showCopiedProblem && <div className={classes.mathEquationContainer}>
            <div className={classes.copiedProblem}>
                <VerticalMathEquation equation={equation} />
            </div>
        </div>}
        <Button className={classes.submitButton} onClick={submit} primary>Submit</Button>
        <Button className={classes.clearButton} onClick={clear}>Clear Board</Button>
        {showCopyButton && <Button className={classes.copyButton} onClick={copyProblem}>Copy Problem</Button>}
        {showCopiedProblem && <Button className={classes.copyButton} onClick={copyProblem}>Hide Copy</Button>}
    </div>
}

export default MathPage;